import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';

import {useDispatch} from "react-redux";

import {
  InventoryPlan,
  MdWeekDate,
  ShipmentReason,
} from '../../../api-client';

import {
  StandardOfInventoryTooltip,
  TextBox,
  TinyDropdown,
  Tooltip,
} from '../../atoms';

import {
  CrossIcon,
  DisableCrossIcon,

} from './style';
import './InventoryPlanTable.css';

import { useSelector } from '../../../stores';
import { scrollSlice } from '../../../stores/scroll';
import { ExpectedSalesByUserCell } from './InputCell/ExpectedSalesByUserCell';
import { inventoryPlanTableDataBgColorStyle, inventoryPlanTableDataStyle } from './_components/styleUtil';

export interface InventoryPlanTableProps {

  /**
   * JANコード
   */
  productCode: string

  /**
   * 需要予測データがあるかどうか
   */
  hasDemandInsightData: boolean

  /**
   * MD週ごとの在庫計画
   */
  inventoryPlanPerMdWeek: { [key: string]: InventoryPlan; }

  /**
   * 過去のMD週
   */
  pastMdWeekDates: Array<MdWeekDate>

  /**
   * 今日・未来のMD週
   */
  presentAndFutureMdWeekDates: Array<MdWeekDate>

  /**
   * ハイライト対象のカラム
   */
  highlightedColIndex: number

  /**
   * 読み取り専用かどうか
   */
  readonly: boolean


  /**
   * 調整アラートが停止可能か
   */
  isStoppableAdjustmentAlert: boolean

  /**
   * 出荷加減要素が変更された時に呼び出されるハンドラー
   */
  onShipmentReasonChangeHandler: (mdWeekDate: MdWeekDate, shipmentReason: ShipmentReason | null) => void

  /**
   * 出荷加減数が変更された時に呼び出されるハンドラー
   */
  onShipmentQuantityChangeHandler: (mdWeekDate: MdWeekDate, quantity: number | null, hasError: boolean) => void

  /**
   * 発注確定数が変更された時に呼び出されるハンドラー
   */
  onConfirmedOrderQuantityChangeHandler: (mdWeekDate: MdWeekDate, quantity: number | null, hasError: boolean) => void

  /**
   * 店頭在庫基準数が変更された時に呼び出されるハンドラー
   */
  onStoreInventoryQuantityStandardChangeHandler: (mdWeekDate: MdWeekDate, quantity: number, hasError: boolean) => void

  /**
   * 入力フォームからフォーカスが外れた時に呼び出されるハンドラー
   */
  onBlurInputHandler: () => void

  /**
   * ハイライト対象のカラムが変更された時に呼び出されるハンドラー
   */
  onHighlightedColIndexChangeHandler: (index: number) => void

  /**
   * 修正発注数の横の×ボタンが押された時に呼び出されるハンドラー
   */
  onClickStopAdjustmentAlertHandler: (date: string, prodcutCode: string) => void

  /**
   * 販売予測数(ユーザー)が変更された時に呼び出されるハンドラー
   */
  onExpectedSalesAmountByUserChangeHandler: (mdWeekDate: MdWeekDate, quantity: number | null, hasError: boolean) => void
}

export const mdWeekYearNumStr = (mdWeekDate: MdWeekDate) => `${mdWeekDate.mdYear}-${mdWeekDate.mdWeekNum}`;

const shipmentReasons = [
  {
    value: ShipmentReason.NotSelected,
    labelSelected: '',
    labelSelectable: '未選択',
  },
  {
    value: ShipmentReason.SpecialPromotion,
    labelSelected: '特売・プロモ',
    labelSelectable: '特売・プロモ',
  },
  {
    value: ShipmentReason.StoreInventoryExpansion,
    labelSelected: '店頭在庫拡大',
    labelSelectable: '店頭在庫拡大',
  },
];

export const InventoryPlanTable: React.FC<InventoryPlanTableProps> = ({
  productCode,
  hasDemandInsightData,
  inventoryPlanPerMdWeek,
  pastMdWeekDates,
  presentAndFutureMdWeekDates,
  highlightedColIndex,
  readonly,
  isStoppableAdjustmentAlert,
  onShipmentReasonChangeHandler,
  onShipmentQuantityChangeHandler,
  onConfirmedOrderQuantityChangeHandler,
  onStoreInventoryQuantityStandardChangeHandler,
  onBlurInputHandler,
  onHighlightedColIndexChangeHandler,
  onClickStopAdjustmentAlertHandler,
  onExpectedSalesAmountByUserChangeHandler,
}) => {
  const timeoutId = useRef<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [mouseOverConfirmedOrderQuantityColumn, setMouseOverConfirmedOrderQuantityColumn] = useState(-1);
  const [mouseOverAdjustedOrderQuantityColumn, setMouseOverAdjustedOrderQuantityColumn] = useState(-1);
  const weakYearList = presentAndFutureMdWeekDates.map(mdWeekDate => mdWeekYearNumStr(mdWeekDate));
  const initailExpectedSalesAmountByUserList = weakYearList.reduce((prev, current) => ({ ...prev, [current]: null }), {});
  const [pastedExpectedSalesAmountByUserList, setPastedExpectedSalesAmountByUserList] = useState<{[weakYear in string]: string | null}>(initailExpectedSalesAmountByUserList);

  const scrollLeft = useSelector(state => state.left);

  const showTooltipForConfirmedOrderQuantityOnLeft = (weekIndex: number) => {
    // 当週のセルより左に見えるセルが2個以上か
    return (weekIndex*80-scrollLeft)/80 >= 2;
  };

  const showTooltipForAdjustedOrderQuantityOnLeft = (weekIndex: number) => {
    // 当週のセルより左に見えるセルが8個以上か
    return (weekIndex*80-scrollLeft)/80 >= 8;
  };

  const dispatch = useDispatch();
  const { actions } = scrollSlice;

  useEffect(() => {
    // jsdom may not have scrollTo method. Ref: https://github.com/jsdom/jsdom/issues/1422
    if (containerRef.current != null && containerRef.current.scrollTo != null) {
      containerRef.current.scrollTo(scrollLeft, 0);
    }
  }, [scrollLeft]);

  const baseInventoryPlanTableWidth = useMemo(() => {
    const width = 144 + 80 * pastMdWeekDates.length + 80 * presentAndFutureMdWeekDates.length;
    return `${width}px`;
  }, [pastMdWeekDates.length, presentAndFutureMdWeekDates.length]);
  
  return(
    <div className='inventory-plan-table-container'
      ref={containerRef}
      onScroll={(elem) => {
        if (timeoutId.current != null) {
          clearTimeout(timeoutId.current);
          timeoutId.current = null;
        }
        const currentTargetScrollLeft = elem.currentTarget.scrollLeft;
        timeoutId.current = window.setTimeout(() => {
          dispatch(actions.scroll(currentTargetScrollLeft));
        }, 300);
      }}
      data-testid='inventory-plan-table'
    >
      <table className='base-inventory-plan-table' style={{ width: baseInventoryPlanTableWidth }}>
        <colgroup>
          <col style={{ width: '144px' }} />
          {pastMdWeekDates.map((mdWeekDate) => {
            const wy = mdWeekYearNumStr(mdWeekDate);
            return (
              <col key={wy} style={{ width: '80px' }} />
            );
          })}
          {presentAndFutureMdWeekDates.map((mdWeekDate) => {
            const wy = mdWeekYearNumStr(mdWeekDate);
            return (
              <col key={wy} style={{ width: '80px' }} />
            );
          })}
        </colgroup>
        <thead>
          <tr>
            <th className='inventory-plan-table-header bold-bottom-border tall-header' />
            <th className='inventory-plan-table-header bold-bottom-border tall-header' colSpan={pastMdWeekDates.length}>実績</th>
            <th className='inventory-plan-table-header bold-bottom-border tall-header' colSpan={presentAndFutureMdWeekDates.length}>予測値</th>
          </tr>
          <tr>
            <th className='inventory-plan-table-header'>MD週</th>
            {pastMdWeekDates.map((mdWeekDate, idx) => (
              <th
                className='inventory-plan-table-header align-right'
                key={`${mdWeekYearNumStr(mdWeekDate)}`}
                onClick={() => onHighlightedColIndexChangeHandler(idx)}
              >{mdWeekDate.mdWeekNum}週</th>
            ))}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => (
              <th
                className='inventory-plan-table-header align-right'
                key={`${mdWeekYearNumStr(mdWeekDate)}`}
                onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
              >{mdWeekDate.mdWeekNum}週</th>
            ))}
          </tr>
          <tr>
            <th className='inventory-plan-table-header label'>日付</th>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              return (
                <th
                  className='inventory-plan-table-header align-right'
                  key={`${mdWeekYearNumStr(mdWeekDate)}`}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >{`${mdWeekDate.date.getMonth()+1}/${mdWeekDate.date.getDate()}`}</th>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              return (
                <th
                  className='inventory-plan-table-header align-right'
                  key={`${mdWeekYearNumStr(mdWeekDate)}`}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >{`${mdWeekDate.date.getMonth()+1}/${mdWeekDate.date.getDate()}`}</th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="inventory-plan-table-data label">販売予測数(AI)</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={ inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, idx === highlightedColIndex) }
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.expectedSalesAmountByAI != null? inventoryPlanPerMdWeek[wy].expectedSalesAmountByAI : ''}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, pastMdWeekDates.length + idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.expectedSalesAmountByAI != null? inventoryPlanPerMdWeek[wy].expectedSalesAmountByAI : ''}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className="inventory-plan-table-data label">販売予測数(ユーザー)</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={ inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, idx === highlightedColIndex) }
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.expectedSalesAmountByUser != null? inventoryPlanPerMdWeek[wy].expectedSalesAmountByUser : ''}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              const expectedSalesAmountByUser = inventoryPlanPerMdWeek[wy].expectedSalesAmountByUser;
              const pastedExpectedSalesAmountByUser = pastedExpectedSalesAmountByUserList[wy];
              // const pastableColumnsNumber = presentAndFutureMdWeekDates.length - idx;
              const isDisabled = readonly || hasDemandInsightData === false;

              return (
                <ExpectedSalesByUserCell
                  key={wy}
                  mdWeekDate={mdWeekDate}
                  wy={wy}
                  expectedSalesAmountByUser={expectedSalesAmountByUser}
                  pastedExpectedSalesAmountByUser={pastedExpectedSalesAmountByUser}
                  isDisabled={isDisabled}
                  highlighted={pastMdWeekDates.length + idx === highlightedColIndex}
                  isDemandAggregationTarget={inventoryPlanPerMdWeek[wy].isDemandAggregationTarget}
                  idx={idx}
                  pastMdWeekDatesLength={pastMdWeekDates.length}
                  onExpectedSalesAmountByUserChangeHandler={onExpectedSalesAmountByUserChangeHandler} // memo化
                  onHighlightedColIndexChangeHandler={onHighlightedColIndexChangeHandler}
                  onBlurInputHandler={onBlurInputHandler}
                  setPastedExpectedSalesAmountByUserList={setPastedExpectedSalesAmountByUserList}
                  presentAndFutureMdWeekDates={presentAndFutureMdWeekDates} // でかい配列/objのはず、なので必要最小限を渡せば良いはず
                  productCode={productCode}
                />
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data label'>販売実績数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.actualSalesAmount != null? inventoryPlanPerMdWeek[wy].actualSalesAmount : ''}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, pastMdWeekDates.length + idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.actualSalesAmount != null? inventoryPlanPerMdWeek[wy].actualSalesAmount : ''}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data bold-bottom-border label'>昨年販売数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(true, idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.lastMdYearActualSalesAmount != null? inventoryPlanPerMdWeek[wy].lastMdYearActualSalesAmount : ''}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(true, pastMdWeekDates.length + idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.lastMdYearActualSalesAmount != null? inventoryPlanPerMdWeek[wy].lastMdYearActualSalesAmount : ''}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data label'>推奨発注数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {/* 過去分の推奨発注数は非表示 */}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(inventoryPlanPerMdWeek[wy]?.recommendedOrderQuantity != null, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, pastMdWeekDates.length + idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.recommendedOrderQuantity != null? inventoryPlanPerMdWeek[wy]?.recommendedOrderQuantity : ''}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data label'>出荷加減要素</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              const shipmentReason = shipmentReasons.find((reason) => reason.value === inventoryPlanPerMdWeek[wy]?.shipmentReason);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  <div className='shipment-reason-label'>{shipmentReason != null? shipmentReason.labelSelected : ''}</div>
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, pastMdWeekDates.length + idx === highlightedColIndex)}
                >
                  <div className="dropdown-container">
                    <TinyDropdown
                      options={shipmentReasons}
                      defaultValue={inventoryPlanPerMdWeek[wy]?.shipmentReason}
                      disabled={readonly}
                      highlightOnChange={true}
                      onChangeHandler={(value) => {
                        const selectedShipmentReason = shipmentReasons.find((reason) => reason.value.toString() === value);
                        onShipmentReasonChangeHandler(mdWeekDate, selectedShipmentReason != null? selectedShipmentReason.value : null);
                      }}
                    />
                  </div>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data label'>出荷加減数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.shipmentQuantity != null? inventoryPlanPerMdWeek[wy]?.shipmentQuantity : ''}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              // 型チェックを通すために一旦変数に格納
              const shipmentQuantity = inventoryPlanPerMdWeek[wy]?.shipmentQuantity;
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, pastMdWeekDates.length + idx === highlightedColIndex)}
                >
                  <div className='text-box-container'>
                    <TextBox
                      id={`${productCode}-${wy}-shipment-quantity-input`}
                      type='number'
                      width={77}
                      height={22}
                      padding={6}
                      defaultValue={inventoryPlanPerMdWeek[wy]?.shipmentQuantity != null? inventoryPlanPerMdWeek[wy]?.shipmentQuantity?.toString() : ''}
                      disabled={readonly}
                      min={-9999999}
                      max={9999999}
                      forceValidate={true}
                      suppressErrorMessage={true}
                      highlightOnChange={true}
                      onChangeHandler={(value, errorMessage) => {
                        let quantity: number | null = Number.parseInt(value);
                        if (Number.isNaN(quantity)) {
                          quantity = null;
                        }
                        onShipmentQuantityChangeHandler(mdWeekDate, quantity, errorMessage != null && errorMessage !== '');
                      }}
                      onBlurHandler={onBlurInputHandler}
                    />
                    {shipmentQuantity != null && shipmentQuantity > 9999999? (<Tooltip text={'9,999,999以内の半角数字で入力してください。'} top={25} width={300} />) : null}
                    {shipmentQuantity != null && shipmentQuantity < -9999999? (<Tooltip text={'-9,999,999以上の半角数字で入力してください。'} top={25} width={305} />) : null}
                  </div>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data label'>発注起案数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {/* 過去分の発注起案数は非表示 */}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, pastMdWeekDates.length + idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.proposalOrderQuantity != null? inventoryPlanPerMdWeek[wy]?.proposalOrderQuantity : ''}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data bold-bottom-border label'>発注確定数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(true, idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                  onMouseOver={() => setMouseOverConfirmedOrderQuantityColumn(idx)}
                  onMouseLeave={() => setMouseOverConfirmedOrderQuantityColumn(-1)}
                >
                  {inventoryPlanPerMdWeek[wy]?.confirmedOrderQuantity != null? inventoryPlanPerMdWeek[wy]?.confirmedOrderQuantity : ''}
                  {inventoryPlanPerMdWeek[wy]?.numberOfSafeDCInventory != null? (
                    <div className='standard-of-inventory-tooltip-container'>
                      <StandardOfInventoryTooltip
                        numberOfWeek={mdWeekDate.mdWeekNum}
                        numberOfSafeDCInventory={inventoryPlanPerMdWeek[wy]?.numberOfSafeDCInventory}
                        numberOfSafeInventoryWeeks={inventoryPlanPerMdWeek[wy]?.numberOfSafeInventoryWeeks}
                        numberOfMaxDCInventory={inventoryPlanPerMdWeek[wy]?.numberOfMaxDCInventory}
                        numberOfMaxInventoryWeeks={inventoryPlanPerMdWeek[wy]?.numberOfMaxInventoryWeeks}
                        deficientQuantity={null}
                        excessQuantity={null}
                        right={showTooltipForConfirmedOrderQuantityOnLeft(idx) === true? -7 : undefined}
                        top={0}
                        visible={mouseOverConfirmedOrderQuantityColumn === idx}
                      />
                    </div>
                  ) : null}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              const proposalOrderQuantity = inventoryPlanPerMdWeek[wy]?.proposalOrderQuantity != null? inventoryPlanPerMdWeek[wy]?.proposalOrderQuantity : null;
              const confirmedOrderQuantity = inventoryPlanPerMdWeek[wy]?.confirmedOrderQuantity != null? inventoryPlanPerMdWeek[wy]?.confirmedOrderQuantity : null;
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(true, pastMdWeekDates.length + idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onMouseOver={() => setMouseOverConfirmedOrderQuantityColumn(pastMdWeekDates.length + idx)}
                  onMouseLeave={() => setMouseOverConfirmedOrderQuantityColumn(-1)}
                >
                  <div className='text-box-container'>
                    <TextBox
                      id={`${productCode}-${wy}-confirmed-order-quantity-input`}
                      type='number'
                      width={77}
                      height={22}
                      padding={6}
                      defaultValue={confirmedOrderQuantity != null? confirmedOrderQuantity?.toString() : ''}
                      placeholder={proposalOrderQuantity != null? proposalOrderQuantity.toString() : ''}
                      disabled={readonly}
                      min={0}
                      max={9999999}
                      forceValidate={true}
                      suppressErrorMessage={true}
                      highlightOnChange={true}
                      onChangeHandler={(value, errorMessage) => {
                        let quantity: number | null = Number.parseInt(value);
                        if (Number.isNaN(quantity)) {
                          quantity = null;
                        }
                        onConfirmedOrderQuantityChangeHandler(mdWeekDate, quantity, errorMessage != null && errorMessage !== '');
                      }}
                      onBlurHandler={onBlurInputHandler}
                    />
                    {confirmedOrderQuantity != null && (confirmedOrderQuantity < 0 || confirmedOrderQuantity > 9999999)? (<Tooltip text={'0~9,999,999以内の半角数字で入力してください。'} top={25} width={320} />) : null}
                  </div>
                  { inventoryPlanPerMdWeek[wy]?.numberOfSafeDCInventory != null ? (
                    <div className='standard-of-inventory-tooltip-container'>
                      <StandardOfInventoryTooltip
                        numberOfWeek={mdWeekDate.mdWeekNum}
                        numberOfSafeDCInventory={inventoryPlanPerMdWeek[wy]?.numberOfSafeDCInventory}
                        numberOfSafeInventoryWeeks={inventoryPlanPerMdWeek[wy]?.numberOfSafeInventoryWeeks}
                        numberOfMaxDCInventory={inventoryPlanPerMdWeek[wy]?.numberOfMaxDCInventory}
                        numberOfMaxInventoryWeeks={inventoryPlanPerMdWeek[wy]?.numberOfMaxInventoryWeeks}
                        deficientQuantity={null}
                        excessQuantity={null}
                        right={showTooltipForConfirmedOrderQuantityOnLeft(pastMdWeekDates.length + idx) === true? -7 : undefined}
                        top={0}
                        visible={mouseOverConfirmedOrderQuantityColumn === pastMdWeekDates.length + idx}
                      />
                    </div>
                  ) : null}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data label'>DC在庫予測数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              const isOrderConfirmed = inventoryPlanPerMdWeek[wy]?.isOrderConfirmed;
              const expectedDCInventoryQuantity = inventoryPlanPerMdWeek[wy]?.expectedDCInventoryQuantity;
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(false, idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, inventoryPlanPerMdWeek[wy]?.hasShortageRiskOfDCInventory === true)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {isOrderConfirmed === false? '(' : ''}
                  {expectedDCInventoryQuantity == null?
                    isOrderConfirmed === false? '-' : ''
                    : expectedDCInventoryQuantity}
                  {isOrderConfirmed === false? ')' : ''}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              const isOrderConfirmed = inventoryPlanPerMdWeek[wy]?.isOrderConfirmed;
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(false, pastMdWeekDates.length + idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, inventoryPlanPerMdWeek[wy]?.hasShortageRiskOfDCInventory === true)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {isOrderConfirmed === false? '(' : ''}
                  {inventoryPlanPerMdWeek[wy]?.expectedDCInventoryQuantity == null?
                    isOrderConfirmed === false? '-' : ''
                    : inventoryPlanPerMdWeek[wy]?.expectedDCInventoryQuantity}
                  {isOrderConfirmed === false? ')' : ''}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data label'>DC在庫週数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              const isOrderConfirmed = inventoryPlanPerMdWeek[wy]?.isOrderConfirmed;
              const numberOfDCInventoryWeeks = inventoryPlanPerMdWeek[wy]?.numberOfDCInventoryWeeks;
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(false, idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, inventoryPlanPerMdWeek[wy]?.hasShortageRiskOfDCInventory === true)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {isOrderConfirmed === false? '(' : ''}
                  {numberOfDCInventoryWeeks == null?
                    isOrderConfirmed === false? '-' : ''
                    : numberOfDCInventoryWeeks}
                  {isOrderConfirmed === false? ')' : ''}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              const isOrderConfirmed = inventoryPlanPerMdWeek[wy]?.isOrderConfirmed;
              const numberOfDCInventoryWeeks = inventoryPlanPerMdWeek[wy]?.numberOfDCInventoryWeeks;
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(false, pastMdWeekDates.length + idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, inventoryPlanPerMdWeek[wy]?.hasShortageRiskOfDCInventory === true)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {isOrderConfirmed === false? '(' : ''}
                  {numberOfDCInventoryWeeks == null?
                    isOrderConfirmed === false? '-' : ''
                    : numberOfDCInventoryWeeks}
                  {isOrderConfirmed === false? ')' : ''}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data bold-bottom-border label'>出荷予測数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(true, idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {/* 過去の実績はデータが無いので、表示しない */}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(true, pastMdWeekDates.length + idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.predictedShipmentQuantity != null ? inventoryPlanPerMdWeek[wy]?.predictedShipmentQuantity : ''}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data label'>修正発注数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {/* 過去分の修正発注数は非表示 */}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              const isShowOnLeft = showTooltipForAdjustedOrderQuantityOnLeft(pastMdWeekDates.length+idx);
              const adjustedOrderQuantity =  inventoryPlanPerMdWeek[wy]?.adjustedOrderQuantity;
              const isAdjustedOrderQuantityExisting = adjustedOrderQuantity!= null;
              const isAdjustmentAlertStopped = inventoryPlanPerMdWeek[wy]?.isAdjustmentAlertStopped === true;
              const isCrossIconDisplayed = isAdjustedOrderQuantityExisting && isAdjustmentAlertStopped === false;
              const isbackgroundred = hasDemandInsightData === true && inventoryPlanPerMdWeek[wy]?.adjustedOrderQuantity != null;
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(false, pastMdWeekDates.length + idx === highlightedColIndex, isbackgroundred, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                  onMouseOver={() => setMouseOverAdjustedOrderQuantityColumn(pastMdWeekDates.length + idx)}
                  onMouseLeave={() => setMouseOverAdjustedOrderQuantityColumn(-1)}
                >
                  {
                    isCrossIconDisplayed === true ? (
                      isStoppableAdjustmentAlert === true?
                        <CrossIcon data-testid="cross-icon" onClick={() => onClickStopAdjustmentAlertHandler(mdWeekYearNumStr(mdWeekDate),productCode)}></CrossIcon>:
                        <DisableCrossIcon data-testid="disable-cross-icon"></DisableCrossIcon>
                    ) : ''
                  }
                  {hasDemandInsightData === false?
                    '' : inventoryPlanPerMdWeek[wy]?.adjustedOrderQuantity != null?
                      inventoryPlanPerMdWeek[wy]?.adjustedOrderQuantity : ''
                  }
                  {hasDemandInsightData === true && inventoryPlanPerMdWeek[wy]?.numberOfSafeDCInventory != null? (
                    <div className='standard-of-inventory-tooltip-container'>
                      <StandardOfInventoryTooltip
                        numberOfWeek={mdWeekDate.mdWeekNum}
                        numberOfSafeDCInventory={inventoryPlanPerMdWeek[wy]?.numberOfSafeDCInventory}
                        numberOfSafeInventoryWeeks={inventoryPlanPerMdWeek[wy]?.numberOfSafeInventoryWeeks}
                        numberOfMaxDCInventory={inventoryPlanPerMdWeek[wy]?.numberOfMaxDCInventory}
                        numberOfMaxInventoryWeeks={inventoryPlanPerMdWeek[wy]?.numberOfMaxInventoryWeeks}
                        deficientQuantity={inventoryPlanPerMdWeek[wy]?.deficientQuantity}
                        excessQuantity={inventoryPlanPerMdWeek[wy]?.excessQuantity}
                        bottom={-2}
                        left={isShowOnLeft === true? undefined : 79}
                        right={isShowOnLeft === true? 72 : undefined}
                        visible={mouseOverAdjustedOrderQuantityColumn === pastMdWeekDates.length + idx}
                      />
                    </div>
                  ) : null}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data label'>不足数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {/* 過去分の不足数は非表示 */}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, pastMdWeekDates.length + idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.deficientQuantity != null ? inventoryPlanPerMdWeek[wy].deficientQuantity : ''}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data bold-bottom-border label'>超過数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(true, idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {/* 過去分の超過数は非表示 */}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(true, pastMdWeekDates.length + idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.excessQuantity != null ? inventoryPlanPerMdWeek[wy].excessQuantity : '' }
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data bold-bottom-border label'>M3</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(true, idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.m3 != null? Math.ceil((inventoryPlanPerMdWeek[wy]?.m3 || 0) * 100) / 100 : ''}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(true, pastMdWeekDates.length + idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.m3 != null? Math.ceil((inventoryPlanPerMdWeek[wy]?.m3 || 0) * 100) / 100 : ''}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data label'>店頭在庫基準数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataStyle(false, idx === highlightedColIndex, false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, false)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {inventoryPlanPerMdWeek[wy]?.storeInventoryQuantityStandard != null? inventoryPlanPerMdWeek[wy]?.storeInventoryQuantityStandard : ''}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              const storeInventoryQuantityStandard = inventoryPlanPerMdWeek[wy]?.storeInventoryQuantityStandard != null? inventoryPlanPerMdWeek[wy]?.storeInventoryQuantityStandard : null;
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, pastMdWeekDates.length + idx === highlightedColIndex)}
                >
                  {storeInventoryQuantityStandard != null? (
                    <div className='text-box-container'>
                      <TextBox
                        id={`${productCode}-${wy}-store-inventory-quantity-standard-input`}
                        type='number'
                        width={77}
                        height={22}
                        padding={6}
                        defaultValue={storeInventoryQuantityStandard.toString()}
                        disabled={readonly}
                        required={true}
                        min={1}
                        max={9999999}
                        forceValidate={true}
                        suppressErrorMessage={true}
                        highlightOnChange={true}
                        onChangeHandler={(value, errorMessage) => {
                          const maybeQuantity = Number.parseInt(value);
                          onStoreInventoryQuantityStandardChangeHandler(
                            mdWeekDate,
                            Number.isNaN(maybeQuantity) === true? 0 : maybeQuantity,
                            errorMessage != null && errorMessage !== ''
                          );
                        }}
                        onBlurHandler={onBlurInputHandler}
                      />
                      {storeInventoryQuantityStandard < 1 || storeInventoryQuantityStandard > 9999999? (<Tooltip text={'1〜9,999,999以内の半角数字で入力して下さい。'} top={25} width={320} />) : null}
                    </div>
                  ) : null}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className='inventory-plan-table-data label'>店頭在庫数</td>
            {pastMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              const isOrderConfirmed = inventoryPlanPerMdWeek[wy]?.isOrderConfirmed;
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(idx)}
                >
                  {isOrderConfirmed === false? '(' : ''}
                  {inventoryPlanPerMdWeek[wy]?.storeInventoryQuantity == null?
                    isOrderConfirmed === false? '-' : ''
                    : inventoryPlanPerMdWeek[wy]?.storeInventoryQuantity?.toLocaleString()}
                  {isOrderConfirmed === false? ')' : ''}
                </td>
              );
            })}
            {presentAndFutureMdWeekDates.map((mdWeekDate, idx) => {
              const wy = mdWeekYearNumStr(mdWeekDate);
              const isOrderConfirmed = inventoryPlanPerMdWeek[wy]?.isOrderConfirmed;
              return (
                <td
                  className='inventory-plan-table-data'
                  key={wy}
                  style={inventoryPlanTableDataBgColorStyle(false, inventoryPlanPerMdWeek[wy].isDemandAggregationTarget, pastMdWeekDates.length + idx === highlightedColIndex)}
                  onClick={() => onHighlightedColIndexChangeHandler(pastMdWeekDates.length + idx)}
                >
                  {isOrderConfirmed === false? '(' : ''}
                  {inventoryPlanPerMdWeek[wy]?.storeInventoryQuantity == null?
                    isOrderConfirmed === false? '-' : ''
                    : inventoryPlanPerMdWeek[wy]?.storeInventoryQuantity?.toLocaleString()}
                  {isOrderConfirmed === false? ')' : ''}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
