import * as Sentry from "@sentry/react";
import firebase from 'firebase/app';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Configuration,
  DefaultApi,
  GetDestockingOrderGroupCsvRequest,
  GetDestockingOrderGroupRequest,
  MdWeekDate,
  OrderDetail,
  OrderGroup,
  OrderSummary,
  ProductWithDetailShortageToleranceRankEnum,
  PutDestockingOrderGroupIncomingMdWeekBackwardRequest,
  PutDestockingOrderGroupIncomingMdWeekForwardRequest,
  PutDestockingOrderGroupOperationRequest,
  PutDestockingOrderGroupOrderOperationRequest,
  PutDestockingOrderGroupOrderRequest,
  PutDestockingOrderGroupRequest,
  PutDestockingOrderGroupRequestInventoryPlanPerMdWeek,
  PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum,
  ShipmentReason,
} from '../api-client';

import { useValidationError } from '../pages/Destocking/hooks/useValidationError';
import { hasValidationError } from '../pages/Destocking/hooks/validationUtil';
import { putSkuMeta } from '../requests/putSkuMeta';
import { mdWeekYearNumStr } from "../components/molecules";

/**
 * 再レンダリングを防ぐために一括で管理
 */
type Destocking = {
  orderGroup: OrderGroup | undefined,
  orderSummary: OrderSummary | undefined,
  orderDetail: OrderDetail | undefined,
  statusCode: number | undefined
};

type LastEditedNCycleParameter =
  | "leadtime"
  | "number-of-order-cycles"
  | "number-of-order-cycle-weeks";

/**
 * 詳細ページのhandler/state管理/更新リクエストを管理するhooks
 * @param orderGroupId 
 * @returns 
 */
export const useDestocking = (orderGroupId: number) => {
  // const [statusCode, setStatusCode] = useState<number | undefined>(undefined);
  const [destocking, setDestocking] = useState<Destocking>({
    orderGroup: undefined,
    orderSummary: undefined,
    orderDetail: undefined,
    statusCode: undefined
  });
  const orderDetail = destocking.orderDetail;

  // simulation用state
  const [simOrderGroup, setSimOrderGroup] = useState<OrderGroup | undefined>();

  const convShortageToleranceRankType = (rank: ProductWithDetailShortageToleranceRankEnum): PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum => {
    switch (rank) {
      case ProductWithDetailShortageToleranceRankEnum.S:
        return PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum.S;
      case ProductWithDetailShortageToleranceRankEnum.A:
        return PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum.A;
      case ProductWithDetailShortageToleranceRankEnum.B:
        return PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum.B;
      case ProductWithDetailShortageToleranceRankEnum.C:
        return PutDestockingOrderGroupRequestProductsShortageToleranceRankEnum.C;
    }
  };

  useEffect(() => {
    firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const param: GetDestockingOrderGroupRequest = {
          orderGroupId
        };
        return api.getDestockingOrderGroup(param);
      })
      .then((response) => {
        setDestocking({
          orderGroup: response.orderGroup,
          orderSummary: response.orderSummary,
          orderDetail: response.orderDetail,
          statusCode: 200
        });

        setSimOrderGroup(response.orderGroup);
      })
      .catch((err: Response) => {
        Sentry.captureException(err);
        setDestocking(prev => ({
          ...prev,
          statusCode: err.status
        }));
      });
  }, [orderGroupId]);

  // validation
  const {validationError, updateValidationErrorField} = useValidationError({
    simOrderDetail: destocking.orderDetail
  });
  const [recalculationMessageVisible, setRecalculationMessageVisible] = useState(false);

  // ------- 更新ロジック -----------------------------
  const putDestocking = (orderGroup: OrderGroup, orderDetail: OrderDetail) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const bodyParam: PutDestockingOrderGroupRequest = {
          orderGroup: {
            leadtime: orderGroup.leadtime,
            numberOfOrderCycles: orderGroup.numberOfOrderCycles,
            numberOfOrderCycleWeeks: orderGroup.numberOfOrderCycleWeeks,
            plannedAt: orderGroup.plannedAt,
            useSubcategoryPrediction: orderGroup.useSubcategoryPrediction,
          },
          products: orderDetail.products.map((product) => {
            const inventoryPlanPerMdWeek: { [key: string]: PutDestockingOrderGroupRequestInventoryPlanPerMdWeek; } = {};
            Object.keys(product.inventoryPlanPerMdWeek).forEach((mdWeek) => {
              inventoryPlanPerMdWeek[mdWeek] = {
                mdWeekDate: product.inventoryPlanPerMdWeek[mdWeek].mdWeekDate,
                shipmentReason: product.inventoryPlanPerMdWeek[mdWeek].shipmentReason,
                shipmentQuantity: product.inventoryPlanPerMdWeek[mdWeek].shipmentQuantity,
                confirmedOrderQuantity: product.inventoryPlanPerMdWeek[mdWeek].confirmedOrderQuantity,
                storeInventoryQuantityStandard: product.inventoryPlanPerMdWeek[mdWeek].storeInventoryQuantityStandard,
                isAdjustmentAlertStopped: product.inventoryPlanPerMdWeek[mdWeek].isAdjustmentAlertStopped,
                expectedSalesAmountByUser: product.inventoryPlanPerMdWeek[mdWeek].expectedSalesAmountByUser
              };
            });
            return {
              code: product.code,
              shortageToleranceRank: convShortageToleranceRankType(product.shortageToleranceRank),
              inventoryPlanPerMdWeek,
            };
          })
        };
        const param: PutDestockingOrderGroupOperationRequest = {
          orderGroupId,
          bodyParam,
        };
        return api.putDestockingOrderGroup(param)
          .then(() => {
            const conf = new Configuration({
              basePath: process.env.REACT_APP_API_PATH,
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const api = new DefaultApi(conf);
            const param: GetDestockingOrderGroupRequest = {
              orderGroupId
            };
            return api.getDestockingOrderGroup(param);
          }).then((response) => {
            // TODO:: 値として変わるもの、変わらないもので持ち替えた方が再レンダリングは少なくなる
            setDestocking({
              orderGroup: response.orderGroup,
              orderSummary: response.orderSummary,
              orderDetail: response.orderDetail,
              statusCode: 200
            });

            setSimOrderGroup(response.orderGroup);
          })
          .catch((error: Response) => {
            Sentry.captureException(error);
            setDestocking(prev => ({
              ...prev,
              statusCode: error.status
            }));
          });
      })
      .catch((err: Response) => {
        Sentry.captureException(err);
        setDestocking(prev => ({
          ...prev,
          statusCode: err.status
        }));
      });
  };

  /**
   * 発注ボタンを押した時に利用される更新処理
   * @param orderGroup 
   * @returns 
   */
  const putDestockingOrder = (orderGroup: OrderGroup) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const bodyParam: PutDestockingOrderGroupOrderRequest = {
          plannedAt: orderGroup.plannedAt,
        };
        const param: PutDestockingOrderGroupOrderOperationRequest = {
          orderGroupId,
          bodyParam,
        };

        return api.putDestockingOrderGroupOrder(param)
          .then(() => {
            const conf = new Configuration({
              basePath: process.env.REACT_APP_API_PATH,
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const api = new DefaultApi(conf);
            const param: GetDestockingOrderGroupRequest = {
              orderGroupId
            };
            return api.getDestockingOrderGroup(param);
          }).then((response) => {
            setDestocking({
              orderGroup: response.orderGroup,
              orderSummary: response.orderSummary,
              orderDetail: response.orderDetail,
              statusCode: 200
            });

            setSimOrderGroup(response.orderGroup);
          })
          .catch((err: Response) => {
            Sentry.captureException(err);
            setDestocking(prev => ({
              ...prev,
              statusCode: err.status
            }));
          });
      })
      .catch((err: Response) => {
        Sentry.captureException(err);
        setDestocking(prev => ({
          ...prev,
          statusCode: err.status
        }));
      });
  };

  const getCSVFile = (orderGroupId: number) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const params: GetDestockingOrderGroupCsvRequest = {
          orderGroupId,
        };
        const api = new DefaultApi(conf);
        return api.getDestockingOrderGroupCsvRaw(params)
          .then((response) => {
            return response.value()
              .then((blob) => {
                const disposition = response.raw.headers.get('content-disposition');
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition != null ? disposition : '');

                let filename;
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, '');
                  filename = decodeURI(filename);
                }

                return { filename, blob };
              });
          });
      });
  };

  const putDestockingOrderGroupIncomingMdWeekBackward = (orderGroup: OrderGroup, incomingMdWeek: number, incomingMdYear: number, incomingDate: Date) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const bodyParam: PutDestockingOrderGroupIncomingMdWeekBackwardRequest = {
          targetMdWeekDate: {
            mdWeekNum: incomingMdWeek,
            mdYear: incomingMdYear,
            date: incomingDate,
          },
          plannedAt: orderGroup.plannedAt,
        };
        const param = {
          orderGroupId,
          bodyParam,
        };
        return api.putDestockingOrderGroupIncomingMdWeekBackward(param)
          .then(() => {
            const conf = new Configuration({
              basePath: process.env.REACT_APP_API_PATH,
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const api = new DefaultApi(conf);
            const param: GetDestockingOrderGroupRequest = {
              orderGroupId
            };
            return api.getDestockingOrderGroup(param);
          }).then((response) => {
            setDestocking({
              orderGroup: response.orderGroup,
              orderSummary: response.orderSummary,
              orderDetail: response.orderDetail,
              statusCode: 200
            });

            setSimOrderGroup(response.orderGroup);
          })
          .catch((error: Response) => {
            setDestocking(prev => ({
              ...prev,
              statusCode: error.status
            }));
          });
      });
  };

  const putDestockingOrderGroupIncomingMdWeekForward = (orderGroup: OrderGroup, incomingMdWeek: number, incomingMdYear: number, incomingDate: Date) => {
    return firebase.auth().currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const api = new DefaultApi(conf);
        const bodyParam: PutDestockingOrderGroupIncomingMdWeekForwardRequest = {
          targetMdWeekDate: {
            mdWeekNum: incomingMdWeek,
            mdYear: incomingMdYear,
            date: incomingDate,
          },
          plannedAt: orderGroup.plannedAt,
        };
        const param = {
          orderGroupId,
          bodyParam,
        };
        return api.putDestockingOrderGroupIncomingMdWeekForward(param)
          .then(() => {
            const conf = new Configuration({
              basePath: process.env.REACT_APP_API_PATH,
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const api = new DefaultApi(conf);
            const param: GetDestockingOrderGroupRequest = {
              orderGroupId
            };
            return api.getDestockingOrderGroup(param);
          }).then((response) => {
            setDestocking({
              orderGroup: response.orderGroup,
              orderSummary: response.orderSummary,
              orderDetail: response.orderDetail,
              statusCode: 200,
            });

            setSimOrderGroup(response.orderGroup);
          })
          .catch((error: Response) => {
            setDestocking(prev => ({
              ...prev,
              statusCode: error.status
            }));
          });
      });
  };

  const getDestocking = async () => {
    const token = await firebase.auth().currentUser?.getIdToken(true);
    const conf = new Configuration({
      basePath: process.env.REACT_APP_API_PATH,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const api = new DefaultApi(conf);
    const response = await api.getDestockingOrderGroup({ orderGroupId }).catch((err: Response) => {
      Sentry.captureException(err);
      setDestocking(prev => ({
        ...prev,
        statusCode: err.status
      }));
    });
    if (response) {
      setDestocking({
        orderGroup: response.orderGroup,
        orderSummary: response.orderSummary,
        orderDetail: response.orderDetail,
        statusCode: 200
      });
      setSimOrderGroup(response.orderGroup);
    }
  };

  const updateSkuMeta = async (productCode: string, comment: string) => {
    await putSkuMeta(productCode, comment);
    await getDestocking();
  };

  // --------------- simulation用state ----------------------

  const updateOrderGroup = useCallback((updates: Partial<OrderGroup>) => {
    setSimOrderGroup(current => {
      if (!current) {
        return current;
      }
      return {
        ...current,
        ...updates,
      };
    });
  },[setSimOrderGroup]);

  const updateOrderGroupField = useCallback((field: keyof OrderGroup, value: any) => {
    updateOrderGroup({ [field]: value });
  },[updateOrderGroup]);



  // ------------- event handler -------------------------
  const [hasChange, setHasChange] = useState(false); // 差分検知フラグ
  // 未保存の変更があるかどうか。こいつがtrueの時は発注ボタンを押せない
  const hasUnsavedChange = useMemo(() => {
    return hasChange || recalculationMessageVisible;
  }, [hasChange, recalculationMessageVisible]);

  type ChangeInfoRef = {
    updateType: string | null, // 発注案か詳細か
    index: number,
    mdWeekKey: string,
    field: string,
    value: any
  };
  // onBlurの時だけだとvalueしか取れないので、どこが更新されたかをonChangeで追跡する
  const changeInfoRef = useRef<ChangeInfoRef>({
    updateType: null,
    index: -1,
    mdWeekKey: "",
    field: "",
    value: ""
  });
  const updateChangeInfoRef = useCallback((update: Partial<ChangeInfoRef>) => {
    changeInfoRef.current = {
      ...changeInfoRef.current,
      ...update
    };
  },[]);

  /**
   * フォーカスが外れた時に実行される関数
   */
  const handleBlur = useCallback(async() => {
    // 処理中、もしくは変更がない場合は更新しない
    if (recalculationMessageVisible || !changeInfoRef.current.updateType) {
      console.error("isPending or hasChange", recalculationMessageVisible, hasChange);
      return;
    }
    // バリデーションエラーがある場合は更新しない
    if (hasValidationError(simOrderGroup, validationError)) {
      console.error("validation error");
      return;
    }
    // 必要なデータが揃っていない場合は更新しない
    if (simOrderGroup == null || orderDetail == null) return;

    // 同期的にODを計算する
    let updatedOrderGroup = simOrderGroup;
    let updatedOrderDetail = orderDetail;
    if (changeInfoRef.current.updateType === "orderDetail") {
      updatedOrderDetail = {
        ...orderDetail,
        products: orderDetail.products.map((product, idx) => {
          if (idx !== changeInfoRef.current.index) return product;
          const updatedInventoryPlan = {
            ...product.inventoryPlanPerMdWeek[changeInfoRef.current.mdWeekKey],
            [changeInfoRef.current.field]: changeInfoRef.current.value
          };
          return {
            ...product,
            inventoryPlanPerMdWeek: {
              ...product.inventoryPlanPerMdWeek,
              [changeInfoRef.current.mdWeekKey]: updatedInventoryPlan
            }
          };
        })
      };
    } else if (changeInfoRef.current.updateType === "orderGroup") {
      updatedOrderGroup = {
        ...simOrderGroup,
        [changeInfoRef.current.field]: changeInfoRef.current.value
      };
    }

    try {
      setRecalculationMessageVisible(true);

      await putDestocking(
        updatedOrderGroup,
        updatedOrderDetail,
      );
      setHasChange(false); 
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    } finally {
      setRecalculationMessageVisible(false);
      updateChangeInfoRef({
        updateType: null,
        index: -1,
        mdWeekKey: "",
        field: "",
        value: ""
      });
    }
  }, [simOrderGroup, orderDetail, validationError, recalculationMessageVisible]); // eslint-disable-line

  const [
    lastEditedNCycleParameter,
    setLastEditedNCycleParameter,
  ] = useState<LastEditedNCycleParameter>("leadtime");
  
  /**
   * サブカテゴリ予測のチェックボックスの変更ハンドラ
   * 更新タイミングはonChange時
   */
  const handleClickSubCategoryPredictionCheckbox = useCallback(async(value: boolean) => {
    if (simOrderGroup == null || orderDetail == null) return;
    if (simOrderGroup.useSubcategoryPrediction === value) return;
    
    setHasChange(true);
    const updatedOrderGroup = {
      ...simOrderGroup,
      useSubcategoryPrediction: value
    };

    try {
      setRecalculationMessageVisible(true);

      await putDestocking(updatedOrderGroup, orderDetail);
      setHasChange(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    } finally {
      setRecalculationMessageVisible(false);
    }

  }, [simOrderGroup, orderDetail]); // eslint-disable-line

  /**
   * リードタイム(日)の変更ハンドラ
   * 更新タイミングはonBlur時
   */
  const handleLeadtimeChange = useCallback((value: string) => {
    if (simOrderGroup == null) return;

    const maybeLeadtime = Number.parseInt(value);
    const leadtime = Number.isNaN(maybeLeadtime)? 0 : maybeLeadtime;
    if (simOrderGroup.leadtime !== leadtime) {
      updateOrderGroupField("leadtime", leadtime); // stateを更新しないとUIでのvalidation表示がされないので暫定でこれで。。少し遅くなる
      updateChangeInfoRef({
        updateType: "orderGroup",
        field: "leadtime",
        value: leadtime
      });
      setLastEditedNCycleParameter("leadtime");
      setHasChange(true);
    }
  }, [simOrderGroup, updateChangeInfoRef, updateOrderGroupField]);

  /**
   * サイクル回数(週)の変更ハンドラ
   * 更新タイミングはonBlur時
   */
  const handleNumberOfOrderCyclesChange = useCallback((value: string) => {
    if (simOrderGroup == null) return;

    const maybeNumberOfOrderCycles = Number.parseInt(value);
    const numberOfOrderCycles = Number.isNaN(maybeNumberOfOrderCycles) ? 0 : maybeNumberOfOrderCycles;
    if (simOrderGroup.numberOfOrderCycles !== numberOfOrderCycles) {
      updateOrderGroupField("numberOfOrderCycles", numberOfOrderCycles); // stateを更新しないとUIでのvalidation表示がされないので暫定でこれで。。少し遅くなる
      updateChangeInfoRef({
        updateType: "orderGroup",
        field: "numberOfOrderCycles",
        value: numberOfOrderCycles
      });
      setLastEditedNCycleParameter("number-of-order-cycles");
      setHasChange(true);
    }
  }, [simOrderGroup, updateChangeInfoRef, updateOrderGroupField]);

  /**
   * サイクル週数(週)の変更ハンドラ
   * 更新タイミングはonBlur時
   */
  const handleNumberOfOrderCycleWeeksChange = useCallback((value: string) => {
    if (simOrderGroup == null) return;

    const maybeNumberOfOrderCycleWeeks = Number.parseInt(value);
    const numberOfOrderCycleWeeks = Number.isNaN(maybeNumberOfOrderCycleWeeks) ? 0 : maybeNumberOfOrderCycleWeeks;
    if (simOrderGroup.numberOfOrderCycleWeeks !== numberOfOrderCycleWeeks) {
      updateOrderGroupField("numberOfOrderCycleWeeks", numberOfOrderCycleWeeks); // stateを更新しないとUIでのvalidation表示がされないので暫定でこれで。。少し遅くなる
      updateChangeInfoRef({
        updateType: "orderGroup",
        field: "numberOfOrderCycleWeeks",
        value: numberOfOrderCycleWeeks
      });

      setLastEditedNCycleParameter("number-of-order-cycle-weeks");
      setHasChange(true);
    }
  }, [simOrderGroup, updateChangeInfoRef, updateOrderGroupField]);


  /**
   * 欠品ランクの変更ハンドラ
   * 更新タイミングはonChange時
   */
  const handleShortageToleranceRankChange = useCallback(async (index: number, value: ProductWithDetailShortageToleranceRankEnum) => {
    if (orderDetail == null || simOrderGroup == null) return;
    if (orderDetail.products[index].shortageToleranceRank === value) return;

    // put時点でstate書き換えをしているので同期的に更新する
    setHasChange(true);
    const updatedOrderDetail = {
      ...orderDetail,
      products: orderDetail.products.map((product, idx) => {
        if (idx !== index) return product;
        return {
          ...product,
          shortageToleranceRank: value
        };
      })
    };

    try {
      setRecalculationMessageVisible(true);

      await putDestocking(simOrderGroup, updatedOrderDetail);
      setHasChange(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    } finally {
      setRecalculationMessageVisible(false);
    }
  },[orderDetail, simOrderGroup]); // eslint-disable-line

  /**
   * 販売数予測ユーザーの変更ハンドラ
   * 更新タイミングはonBlur時
   */
  const handleExpectedSalesAmountByUserChange = useCallback(
    (
      index: number,
      mdWeekDate: MdWeekDate,
      quantity: number | null,
      hasError: boolean
    ) => {
      const wy = mdWeekYearNumStr(mdWeekDate);
      if (orderDetail?.products[index] == null) {
        return;
      }
      const productCode = orderDetail.products[index].code;
      const currentValidation = validationError[productCode]?.inventoryPlanPerMdWeek?.[wy]?.expectedSalesAmountByUser;
      if (currentValidation !== hasError) {
        updateValidationErrorField(
          productCode,
          wy,
          "expectedSalesAmountByUser",
          hasError
        );
        // validationが発生しても値を更新するので処理止めない
      }
      if (orderDetail.products[index].inventoryPlanPerMdWeek[wy].expectedSalesAmountByUser !== quantity) {
        updateChangeInfoRef({
          updateType: "orderDetail",
          index: index,
          mdWeekKey: wy,
          field: "expectedSalesAmountByUser",
          value: quantity
        });
        setHasChange(true);
      }
    },
    [
      orderDetail,
      validationError,
      updateValidationErrorField,
      updateChangeInfoRef,
    ]
  );

  /**
   * 出荷加減要素の変更ハンドラ
   * 更新タイミングはonChange時
   */
  const handleShipmentReasonChange = useCallback(async (index: number, mdWeekDate: MdWeekDate, value: ShipmentReason | null) => {
    const wy = mdWeekYearNumStr(mdWeekDate);
    if (orderDetail == null || simOrderGroup == null) return;
    if (orderDetail?.products[index] == null) return;
    if (orderDetail.products[index].inventoryPlanPerMdWeek[wy].shipmentReason === value) return;
    if (value == null) return; // 通常はありえない

    setHasChange(true);
    const updatedOrderDetail = {
      ...orderDetail,
      products: orderDetail.products.map((product, idx) => {
        if (idx !== index) return product;
        const updatedInventoryPlan = {
          ...product.inventoryPlanPerMdWeek[wy],
          shipmentReason: value
        };
        return {
          ...product,
          inventoryPlanPerMdWeek: {
            ...product.inventoryPlanPerMdWeek,
            [wy]: updatedInventoryPlan
          }
        };
      })
    };

    try {
      setRecalculationMessageVisible(true);

      await putDestocking(simOrderGroup, updatedOrderDetail);
      setHasChange(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    } finally {
      setRecalculationMessageVisible(false);
    }
  }, [orderDetail, simOrderGroup]); // eslint-disable-line

  /**
   * 出荷加減数の変更ハンドラ
   * 更新タイミングはonBlur時
   */
  const handleShipmentQuantityChangeHandler = useCallback(
    (
      index: number,
      mdWeekDate: MdWeekDate,
      quantity: number | null,
      hasError: boolean
    ) => {
      const wy = mdWeekYearNumStr(mdWeekDate);
      if (orderDetail?.products[index] == null) {
        return;
      }
      const productCode = orderDetail.products[index].code;
      const currentValidation = validationError[productCode]?.inventoryPlanPerMdWeek?.[wy]?.shipmentQuantity;
      if (currentValidation !== hasError) {
        updateValidationErrorField(
          productCode,
          wy,
          "shipmentQuantity",
          hasError
        );
        // validationが発生しても値を更新するので処理止めない
      }
      if (orderDetail.products[index].inventoryPlanPerMdWeek[wy].shipmentQuantity !== quantity) {
        updateChangeInfoRef({
          updateType: "orderDetail",
          index: index,
          mdWeekKey: wy,
          field: "shipmentQuantity",
          value: quantity
        });
        setHasChange(true);
      }
    },
    [
      orderDetail,
      validationError,
      updateValidationErrorField,
      updateChangeInfoRef,
    ]
  );

  /**
  * 発注確定数の変更ハンドラ
  * 更新タイミングはonBlur時
  */
  const handleConfirmedOrderQuantityChangeHandler = useCallback(
    (
      index: number,
      mdWeekDate: MdWeekDate,
      quantity: number | null,
      hasError: boolean
    ) => {
      const wy = mdWeekYearNumStr(mdWeekDate);
      if (orderDetail?.products[index] == null) return;
  
      const productCode = orderDetail.products[index].code;
      const currentValidation = validationError[productCode]?.inventoryPlanPerMdWeek?.[wy]?.confirmedOrderQuantity;
      if (currentValidation !== hasError) {
        updateValidationErrorField(
          productCode,
          wy,
          "confirmedOrderQuantity",
          hasError
        );
        // validationが発生しても値を更新するので処理止めない
      }
      if (orderDetail.products[index].inventoryPlanPerMdWeek[wy].confirmedOrderQuantity !== quantity) {
        updateChangeInfoRef({
          updateType: "orderDetail",
          index: index,
          mdWeekKey: wy,
          field: "confirmedOrderQuantity",
          value: quantity
        });
        setHasChange(true);
      }
    },
    [ orderDetail,validationError,updateValidationErrorField,updateChangeInfoRef]
  );

  /**
   * 店舗在庫標準数の変更ハンドラ
   * 更新タイミングはonBlur時
   */
  const handleStoreInventoryQuantityStandardChangeHandler = useCallback(
    (
      index: number,
      mdWeekDate: MdWeekDate,
      quantity: number | null,
      hasError: boolean
    ) => {
      const wy = mdWeekYearNumStr(mdWeekDate);
      if (orderDetail?.products[index] == null) {
        return;
      }
      const productCode = orderDetail.products[index].code;
      const currentValidation =
        validationError[productCode]?.inventoryPlanPerMdWeek?.[wy]
          ?.storeInventoryQuantityStandard;
      if (currentValidation !== hasError) {
        updateValidationErrorField(
          productCode,
          wy,
          "storeInventoryQuantityStandard",
          hasError
        );
        // validationが発生しても値を更新するので処理止めない
      }
      if (orderDetail.products[index].inventoryPlanPerMdWeek[wy].storeInventoryQuantityStandard !== quantity) {
        updateChangeInfoRef({
          updateType: "orderDetail",
          index: index,
          mdWeekKey: wy,
          field: "storeInventoryQuantityStandard",
          value: quantity
        });
        setHasChange(true);
      }
    },
    [
      orderDetail,
      validationError,
      updateValidationErrorField,
      updateChangeInfoRef,
    ]
  );



  return {
    orderGroup: destocking.orderGroup,
    orderSummary: destocking.orderSummary,
    orderDetail: destocking.orderDetail,
    simOrderGroupState: {
      simOrderGroup,
      setSimOrderGroup,
    },
    statusCode: destocking.statusCode,
    putDestockingOrder,
    getCSVFile,
    putDestocking,
    putDestockingOrderGroupIncomingMdWeekBackward,
    putDestockingOrderGroupIncomingMdWeekForward,
    getDestocking,
    updateSkuMeta,

    // simulation用state
    hasChange,
    // simulation実行時UI制御
    recalculationMessageVisible,
    setRecalculationMessageVisible,

    // validation state
    validationError,

    // イベントハンドラ
    hasUnsavedChange,
    handleBlur,
    // handleBlurSync,
    lastEditedNCycleParameter,
    handleClickSubCategoryPredictionCheckbox,
    handleLeadtimeChange,
    handleNumberOfOrderCyclesChange,
    handleNumberOfOrderCycleWeeksChange,
    handleShortageToleranceRankChange,
    handleShipmentReasonChange,
    handleExpectedSalesAmountByUserChange,
    handleShipmentQuantityChangeHandler,
    handleConfirmedOrderQuantityChangeHandler,
    handleStoreInventoryQuantityStandardChangeHandler,
  };
};
